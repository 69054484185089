import { combineReducers } from "redux";
import adminReducer from './Admin.reducer'
import enquiryReducer from './Enquiry.reducer'
import paymentReducer from './Checkout.reducer'
import blogsReducer from "./Blogs.reducer";

const rootReducer = combineReducers({
    admin: adminReducer,
    enquiry: enquiryReducer,
    payment: paymentReducer,
    blogs: blogsReducer
})

export default rootReducer;