import React from 'react';

const NotFoundPage = () => {
    return (
        <div className='container'>
            <div className='main-notfound flex items-center justify-center min-h-screen'>
                <div>
                    <div className='img-contain static'>
                        <img src="/assets/images/error-img.jpg" alt='error-img' />
                        {/* <h1 className='text-[200px]'>404</h1> */}
                    </div>
                    <div className='px-3' style={{ textAlign: 'center' }}>
                        <h1 className='text-[30px]' style={{ color: '#2F2F2F' }}>Page Not Found</h1>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        <a href="/" className='border-r border-[#271A62] bg-[#271A62] text-white rounded-md px-4 py-2'>Go to Home</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
