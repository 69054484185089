import React from 'react'
import NotFoundPage from './Components/404/NotFoundPage'
import { Helmet } from 'react-helmet'

const notFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Page: Codersbay Technologies</title>
      </Helmet>
      <NotFoundPage />
    </>
  )
}

export default notFound