import { FaMobileAlt } from "react-icons/fa";
import { FaEarthEurope } from "react-icons/fa6";
import { BsCreditCard } from "react-icons/bs";
import { IoBarChartSharp } from "react-icons/io5";
import { MdWebhook } from "react-icons/md";
import { FaGamepad } from "react-icons/fa";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaProductHunt } from "react-icons/fa";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";

var Routes = [
    {
        id: 1,
        title: 'Industries',
        link: '/industries',
        // megaMenu: [
        //     {
        //         id: 1,
        //         title: 'Consumer Goods',
        //     },
        //     {
        //         id: 2,
        //         title: 'Retail & E-Commerce',
        //     },
        //     {
        //         id: 3,
        //         title: 'Travel, Hospitality & Transportation',
        //     },
        //     {
        //         id: 4,
        //         title: 'Public Sector',
        //     },
        //     {
        //         id: 5,
        //         title: 'Health Care',
        //     },
        //     {
        //         id: 6,
        //         title: 'Media & Entertainment',
        //     },
        //     {
        //         id: 7,
        //         title: 'Banking & Finance Services',
        //     },
        //     {
        //         id: 8,
        //         title: 'Education',
        //     },
        //     {
        //         id: 9,
        //         title: 'Manufacturing',
        //     },
        // ]
    },
    {
        id: 2,
        title: 'Services',
        link: '#',
        megaMenu: [
            {
                id: 1,
                title: 'Software Development',
                link: '/software-development'
            },
            {
                id: 2,
                title: 'Mobile App Development',
                icon: <FaMobileAlt style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/mobile-app'
            },
            {
                id: 3,
                title: 'Website Development',
                icon: <FaEarthEurope style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/website-development'
            },
            {
                id: 4,
                title: 'AI & ML',
                icon: <FaEarthEurope style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/AI-ML'
            },
            {
                id: 5,
                title: 'eCommerce',
                icon: <IoBarChartSharp style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/ecommerce'
            },
            {
                id: 6,
                title: 'ERP and CRM',
                icon: <BsCreditCard style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/ERP-CRM'
            },
            {
                id: 7,
                title: 'Blockchain',
                icon: <FaProductHunt style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/blockchain'
            },
            {
                id: 8,
                title: 'Marketing',
                icon: <HiOutlineRocketLaunch style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/marketing'
            },
            {
                id: 9,
                title: 'Emerging Tech',
                link: '/emerging-tech'
            },
            {
                id: 10,
                title: 'Cloud Engineering',
                icon: <FaCloudDownloadAlt style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/cloud'
            },
            {
                id: 11,
                title: 'Hire developers',
                icon: <PiUsersThreeFill style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/hire'
            },
            {
                id: 12,
                title: 'Game Development',
                icon: <FaGamepad style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/game'
            },
            {
                id: 13,
                title: 'View All Services',
                icon: <FaGamepad style={{ marginRight: "10px", width: "20px", height: "20px" }} />,
                link: '/view-all'
            },
        ]
    },
    {
        id: 3,
        title: 'About Us',
        link: '/about-us'
    },
    {
        id: 4,
        title: 'Resources',
        link: '#',
        megaMenu: [
            // {
            //     id: 1,
            //     title: 'Blog',
            //     link: '/blog'
            // },
            // {
            //     id: 2,
            //     title: 'Help Center',
            //     link: '/help-center'
            // },
            {
                id: 3,
                title: 'Partner Program',
                link: '/partner'
            }
        ]
    },
    {
        id: 5,
        title: 'Contact Us',
        link: '/contact-us'
    },
    {
        id: 6,
        title: 'Accounts',
        link: '#',
        megaMenu: [
            // {
            //     id: 1,
            //     title: 'Sign In',
            //     link: '/login'
            // },
            // {
            //     id: 2,
            //     title: 'Sign Up',
            //     link: '/sign-up'
            // },
            {
                id: 3,
                title: 'Payment',
                link: '/payment'
            }
        ]
    }
]

export default Routes;