import { toast } from "react-toastify";
import { BASEURL } from "../../../src/baseUrl";
import axios from "axios";

export const HOME_FORM = 'HOME_FORM';
export const CONTACTUS_FORM = 'CONTACTUS_FORM';
export const PARTNER_FORM = 'PARTNER_FORM';
export const QUOTE_FORM = 'QUOTE_FORM';
export const CAREER_FORM = 'CAREER_FORM';
export const SUBSCRIBE_FORM = 'SUBSCRIBE_FORM';
export const STAYTURN_FORM = 'STAYTURN_FORM';

const homeFormAction = (payload) => ({ type: HOME_FORM, payload: payload });
const contactUsFormAction = (payload) => ({ type: CONTACTUS_FORM, payload: payload });
const partnerFormAction = (payload) => ({ type: PARTNER_FORM, payload: payload });
const quoteFormAction = (payload) => ({ type: QUOTE_FORM, payload: payload });
const careerFormAction = (payload) => ({ type: CAREER_FORM, payload: payload });
const subscribeFormAction = (payload) => ({ type: SUBSCRIBE_FORM, payload: payload });
const stayFormAction = (payload) => ({ type: STAYTURN_FORM, payload: payload });

export const contactAdd = (formData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/home/form`, formData).then((res) => {
                dispatch(homeFormAction(res.data));
                toast.success('Form submited successfully')
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                if (error.response?.data?.code === 404 && error.response?.data?.message === 'Recaptcha Is Required') {
                    toast.error('Recaptcha Is Required');
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(homeFormAction(error?.response?.data));
            })
        } catch (error) {
            console.log("Error", error);
            toast.error('Something went wrong')
        }
    }
}

export const contactUsAdd = (formData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/contact/form`, formData).then((res) => {
                dispatch(contactUsFormAction(res.data));
                toast.success('Form submited successfully')
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                if (error.response?.data?.code === 404 && error.response?.data?.message === 'Recaptcha Is Required') {
                    toast.error('Recaptcha Is Required');
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(contactUsFormAction(error?.response?.data))
            })
        } catch (error) {
            console.log("error", error);
            toast.error('Something went wrong')
        }
    }
}

export const partnerAdd = (formData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/partner/form`, formData).then((res) => {
                dispatch(partnerFormAction(res.data));
                toast.success('Form submited successfully')
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                if (error.response?.data?.code === 404 && error.response?.data?.message === 'Recaptcha Is Required') {
                    toast.error('Recaptcha Is Required');
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(partnerFormAction(error?.response?.data))
            })
        } catch (error) {
            console.log("error", error);
            toast.error('Something went wrong')
        }
    }
}

export const quoteAdd = (formData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/quote/form`, formData).then((res) => {
                dispatch(quoteFormAction(res.data));
                toast.success('Form submited successfully')
                console.log("res", res.data);
            }).catch((error) => {
                dispatch(quoteFormAction(error?.response?.data))
                toast.error('Something went wrong')
            })
        } catch (error) {
            console.log("error", error);
            toast.error('Something went wrong')
        }
    }
}

export const careerAdd = (jobData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/career/form`, jobData).then((res) => {
                dispatch(careerFormAction(res.data));
                toast.success('Form submited successfully')
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                if (error.response?.data?.code === 404 && error.response?.data?.message === 'Recaptcha Is Required') {
                    toast.error('Recaptcha Is Required');
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(careerFormAction(error?.response?.data))
            })
        } catch (error) {
            console.log("error", error);
            toast.error('Something went wrong')
        }
    }
}

export const subscribeAdd = (formData) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${BASEURL}/subscribe/form`, formData);
            dispatch(subscribeFormAction(response.data));
            toast.success('Form submited successfully');
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error:", error);
            toast.error('Something went wrong');
        }
    };
}

export const stayturnAdd = (formData) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${BASEURL}/stayTuned/form`, formData);
            dispatch(stayFormAction(response.data));
            toast.success('Form submited successfully');
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error:", error);
            toast.error('Something went wrong');
        }
    };
}