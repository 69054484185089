import axios from "axios";
import { BASEURL } from "../../baseUrl";

export const PHONEPE_PAYMENT_REQUEST = 'PHONEPE_PAYMENT_REQUEST';
export const PHONEPE_PAYMENT_SUCCESS = 'PHONEPE_PAYMENT_SUCCESS';
export const PHONEPE_PAYMENT_FAIL = 'PHONEPE_PAYMENT_FAIL';
export const PAYMENT_DATA = 'PAYMENT_DATA';

const paymentRequestAction = () => ({ type: PHONEPE_PAYMENT_REQUEST });
const paymentSuccessAction = (payload) => ({ type: PHONEPE_PAYMENT_SUCCESS, payload: payload });
const paymentFailAction = (error) => ({ type: PHONEPE_PAYMENT_FAIL, payload: error });
const paymentDataAction = (payload) => ({ type: PAYMENT_DATA, payload: payload });

export const paymentProcess = (payload) => {
    return async (dispatch) => {
        dispatch(paymentRequestAction());
        try {
            await axios.post(`${BASEURL}/checkout/phonePe`, payload).then(((res) => {
                console.log("res-----------", res.data);
                dispatch(paymentSuccessAction(res.data));
            })).catch((error) => {
                console.log("Error processing payment:", error);
                dispatch(paymentFailAction(error.response ? error.response.data : error.message));
            });
        } catch (error) {
            console.log('Error login user:', error);
        }
    }
}

// export const paymentDataAdd = (formData) => {
//     return async (dispatch) => {
//         try {
//             const response = await axios.post(`${BASEURL}/checkout/form`, formData);
//             dispatch(paymentDataAction(response.data));
//             console.log("Response:", response.data);
//         } catch (error) {
//             console.error("Error:", error);
//             dispatch(paymentDataAction(error))
//             toast.error('Something went wrong');
//         }
//     };
// }

export const paymentDataAdd = (formData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/checkout/phonePeStatus`, formData).then((res) => {
                dispatch(paymentDataAction(res.data));
                console.log("Response:", res.data);
            }).catch((error) => {
                console.error("Error:", error);
                dispatch(paymentDataAction(error))
            })
        } catch (error) {
            console.error("Error:", error);
        }
    };
}