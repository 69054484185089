export const LOGIN_ADMIN = 'LOGIN_ADMIN';

const adminLoginAction = (payload) => ({ type: LOGIN_ADMIN, payload: payload });

// export const adminLogin = (payload) => {
//     return async (dispatch) => {
//         try {
//             await axios.post(`${BASEURL}/admin/login`, payload).then(((res) => {
//                 console.log("res", res);
//                 toast.success('Admin login successfully')
//                 dispatch(adminLoginAction(res));
//             })).catch((error) => {
//                 console.log("fdsfdsfsde", error);
//                 toast.error(error?.response?.data?.message)
//                 dispatch(adminLoginAction(error?.response));
//             });
//         } catch (error) {
//             toast.error(error?.response)

//             console.log('Error login user:', error);
//         }
//     }
// }
