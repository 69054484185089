import {
    PHONEPE_PAYMENT_REQUEST,
    PHONEPE_PAYMENT_SUCCESS,
    PHONEPE_PAYMENT_FAIL,
    PAYMENT_DATA,
} from "../actions/Checkout.action";

const initialState = {
    loading: false,
    sucess: false,
    error: null,
    data: null,
    paymentData: ''
};

export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case PHONEPE_PAYMENT_REQUEST:
            return { ...state, loading: true };
        case PHONEPE_PAYMENT_SUCCESS:
            return { loading: false, success: true, data: action.payload, error: null };
        case PHONEPE_PAYMENT_FAIL:
            return { loading: false, error: action.payload, success: false, data: null };
        case PAYMENT_DATA:
            return { ...state, paymentData: action.payload };
        default:
            return state;
    }
}

export default paymentReducer;