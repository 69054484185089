import axios from "axios";
import { BASEURL } from '../../baseUrl'

export const HOME_BLOG_LIST = 'HOME_BLOG_LIST'
export const INDUSTRY_BLOG_LIST = 'INDUSTRY_BLOG_LIST'
export const SERVICE_BLOG_LIST = 'SERVICE_BLOG_LIST'

const homeBlogAction = (payload) => ({ type: HOME_BLOG_LIST, payload: payload });
const industryBlogAction = (payload) => ({ type: INDUSTRY_BLOG_LIST, payload: payload });
const serviceBlogAction = (payload) => ({ type: SERVICE_BLOG_LIST, payload: payload });

export const homeBlogData = () => {
    return async (dispatch) => {
        try {
            await axios.get(`${BASEURL}/homeBlog/gethomeBlogData`).then((res) => {
                dispatch(homeBlogAction(res?.data));
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                dispatch(homeBlogAction(error?.response?.data))
            })
        } catch (error) {
            console.log("Error", error);
        }
    }
}

export const industryBlogData = () => {
    return async (dispatch) => {
        try {
            await axios.get(`${BASEURL}/industryBlog/getIndustryBlogData`).then((res) => {
                dispatch(industryBlogAction(res.data));
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                dispatch(industryBlogAction(error?.response?.data))
            })
        } catch (error) {
            console.log("Error", error);
        }
    }
}

export const serviceBlogData = (payload) => {
    return async (dispatch) => {
        try {
            await axios.post(`${BASEURL}/blog/getBlogData`, payload).then((res) => {
                dispatch(serviceBlogAction(res.data));
                console.log("res", res.data);
            }).catch((error) => {
                console.log("error", error);
                dispatch(serviceBlogAction(error?.response?.data))
            })
        } catch (error) {
            console.log("Error", error);
        }
    }
}