import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/footer'

const WelcomePage = () => {
    return (
        <div>
            <Header props={{ "bgColor": "white", "color": "black", "btn": "#271A62", "btnColor": "white", "logo": "/assets/images/Logo.svg", "icon": "#444" }} />
            <Footer props={{ "logo": "/assets/images/Logo.svg", "bgColor": "#E6EBF5", "title": "#271A62", "text": "black", "copyright": "#271A62" }} />
        </div>
    )
}

export default WelcomePage