import { HOME_BLOG_LIST, INDUSTRY_BLOG_LIST, SERVICE_BLOG_LIST } from "../actions/Blogs.action";

const initialState = {
    homeBlogList: "",
    industryBlogList: "",
    serviceBlogList: ""
};

const blogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case HOME_BLOG_LIST:
            return { ...state, homeBlogList: action.payload };
        case INDUSTRY_BLOG_LIST:
            return { ...state, industryBlogList: action.payload };
        case SERVICE_BLOG_LIST:
            return { ...state, serviceBlogList: action.payload };
        default:
            return state;
    }
}

export default blogsReducer;