import React from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './style/index.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import { Helmet } from 'react-helmet';
import { applyMiddleware, createStore } from 'redux';
import { thunk } from "redux-thunk";
import rootReducer from './redux/reducers/Index.reducer.js';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Welcome from './pages/Welcome.jsx';
import NotFound from './pages/NotFound.jsx';

const Home = React.lazy(() => import('./pages/Home.jsx'))
const Game = React.lazy(() => import('./pages/Game.jsx'))
const Ecommerce = React.lazy(() => import('./pages/Ecommerce.jsx'))
const Hire = React.lazy(() => import('./pages/Hire.jsx'))
const Cloud = React.lazy(() => import('./pages/Cloud.jsx'))
const Digital = React.lazy(() => import('./pages/Digital.jsx'))
const MobileApp = React.lazy(() => import('./pages/MobileApp.jsx'))
const ScrollButton = React.lazy(() => import('./common/ScrollButton.js'))
const AboutUs = React.lazy(() => import('./pages/AboutUs.jsx'))
const Industries = React.lazy(() => import('./pages/Industries.jsx'))
const Blog = React.lazy(() => import('./pages/Blog.jsx'))
const BlogPage = React.lazy(() => import('./pages/BlogPage.jsx'))
const Desclimer = React.lazy(() => import('./pages/Desclimer.jsx'))
const TermsService = React.lazy(() => import('./pages/TermsService.jsx'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy.jsx'))
const CookiePolicy = React.lazy(() => import('./pages/CookiePolicy.jsx'))
const ContactUs = React.lazy(() => import('./pages/ContactUs.jsx'))
const HelpLine = React.lazy(() => import('./pages/HelpLine.jsx'))
const Careers = React.lazy(() => import('./pages/Careers.jsx'))
const ViewAll = React.lazy(() => import('./pages/ViewAll.jsx'))
const WebDevelopment = React.lazy(() => import('./pages/WebDevelopment.jsx'))
const ErpCrm = React.lazy(() => import('./pages/ErpCrm.jsx'))
const SoftwareDevelop = React.lazy(() => import('./pages/SoftwareDevelop.jsx'))
const DataAi = React.lazy(() => import('./pages/DataAi.jsx'))
const ProfessionalServices = React.lazy(() => import('./pages/ProfessionalServices.jsx'))
const EmergingTechnology = React.lazy(() => import('./pages/EmergingTechnology.jsx'))
const NoticeModal = React.lazy(() => import('./common/NoticeModal.js'))
const Partner = React.lazy(() => import('./pages/Partner.jsx'))
const HomeForm = React.lazy(() => import('./common/HomeForm.js'))
const GetStarted = React.lazy(() => import('./pages/GetStarted.jsx'))
const Login = React.lazy(() => import('./pages/Login.jsx'))
const Checkout = React.lazy(() => import('./pages/Checkout.jsx'))
const RefundPolicy = React.lazy(() => import('./pages/RefundPolicy.jsx'))
const Payment = React.lazy(() => import('./pages/Payment.jsx'))
const PaymentSuccess = React.lazy(() => import('./pages/PaymentSuccess.jsx'))

const App = () => {
  const store = createStore(rootReducer, applyMiddleware(thunk));
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
      <ToastContainer />
    </Provider>
  );
};

const AppContent = () => {
  const [isGameActive, setIsGameActive] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const location = useLocation();
  const transactionId = sessionStorage.getItem('transactionId')

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasSeenForm = sessionStorage.getItem('hasSeenForm');
      if (!hasSeenForm) {
        setShowForm(true);
      }
    }, 60000);

    return () => clearTimeout(timer);
  }, []);

  const closeForm = () => {
    sessionStorage.setItem('hasSeenForm', 'true');
    setShowForm(false);
  }

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    sessionStorage.setItem('hasSeenModal', 'true');
    setShowModal(false);
  };

  return (
    <>
      {
        location.pathname === '/' ? <>
          {showModal && <NoticeModal onClose={handleCloseModal} />}
          {showForm && <HomeForm onClose={closeForm} />}
        </> : ''
      }

      <Helmet>
        <title>Codersbay Technologies: Software Development, Marketing and Cloud</title>
        <meta name="description"
          content='Codersbay is a Leading "AI software solutions" provider innovating with advanced AI, machine learning, and data analytics to drive business transformation globally.' />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="game"
          element={<Game setIsGameActive={setIsGameActive} />}
        />
        <Route path="ecommerce" element={<Ecommerce />} />
        <Route path="hire" element={<Hire setIsGameActive={setIsGameActive} />} />
        <Route path="cloud" element={<Cloud />} />
        <Route path="marketing" element={<Digital />} />
        <Route path="mobile-app" element={<MobileApp />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="industries" element={<Industries />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog-page" element={<BlogPage />} />
        <Route path="desclimer" element={<Desclimer />} />
        <Route path="terms-service" element={<TermsService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="help-center" element={<HelpLine />} />
        <Route path="careers" element={<Careers />} />
        <Route path="view-all" element={<ViewAll />} />
        <Route path="website-development" element={<WebDevelopment />} />
        <Route path="ERP-CRM" element={<ErpCrm />} />
        <Route path="software-development" element={<SoftwareDevelop />} />
        <Route path="AI-ML" element={<DataAi />} />
        <Route path="blockchain" element={<ProfessionalServices />} />
        <Route path="emerging-tech" element={<EmergingTechnology />} />
        <Route path="partner" element={<Partner />} />
        <Route path="sign-up" element={<GetStarted />} />
        <Route path="login" element={<Login />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="payment" element={<Payment />} />
        <Route path="welcome" element={<Welcome />} />
        <Route path='*' element={<NotFound />} />
        <Route path={`/payment-success/${transactionId}`} element={<PaymentSuccess />} />
      </Routes>

      {isGameActive ? (
        <ScrollButton isDarkTheme={false} />
      ) : (
        <ScrollButton isDarkTheme={true} />
      )}
    </>
  );
}

export default App;
