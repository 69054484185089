import React, { useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Routes from './routes';

const Offcanvase = ({ props }) => {
    const [menu, setMenu] = useState(null);
    const [isHovered, setIsHovered] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleHover = (id) => {
        setIsHovered(id);
    };

    const handleLeave = () => {
        setIsHovered(null);
    };

    const handleMenuClick = (id) => {
        setMenu(menu === id ? null : id);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <div className='relative mobile_offcanvas'>
            <nav className={`navbar shadow d-lg-none bg-${props.bgColor} d-block ${props.dark}`}>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={props.logo} className='w-[150px]' alt="logo" />
                    </a>
                    <div className='flex items-center'>
                        <button className="navbar-toggler duration-500" type="button" onClick={toggleMenu} aria-label="Toggle navigation">
                            {isMenuOpen ? <AiOutlineClose size={24} className={`text-${props.color}`} /> : <AiOutlineMenu size={24} className={`text-${props.color}`} />}
                        </button>
                    </div>
                </div>
            </nav>
            {
                isMenuOpen &&
                <div className={`bg-${props.bgColor} ${props.dark} z-20 absolute shadow-[0px_15px_15px_rgba(0,0,0,0.1)] top-full left-0 w-100`}>
                    <div className="container">
                        <ul className="navbar-nav justify-content-end flex-grow-1 py-3">
                            {Routes.map((item) => (
                                <li key={item.id} className={`nav-item border-t border-[#f0f0f0]`} onMouseEnter={() => handleHover(item.id)} onMouseLeave={handleLeave}>
                                    <a
                                        className={`nav-link py-2 cursor-pointer text-${props.color}`}
                                        href={item.link}
                                        onClick={() => item.megaMenu && handleMenuClick(item.id)}
                                        style={{ color: isHovered === item.id ? `${props.btn}` : '' }}
                                    >
                                        <span className='flex items-center'>
                                            {item.title}
                                            {item.megaMenu && (
                                                menu === item.id ?
                                                    <TiArrowSortedUp className="dropdown-icon duration-500" /> :
                                                    <TiArrowSortedDown className="dropdown-icon duration-500" />
                                            )}
                                        </span>
                                    </a>

                                    {item.megaMenu && (
                                        <ul className={`submenu ${menu === item.id ? 'open' : ''} bg-${props.bgColor}`}>
                                            {item.megaMenu.map((subItem) => (
                                                <li key={subItem.id} className='nav-item'>
                                                    <a href={subItem.link} style={{ color: isHovered === subItem.id ? `${props.btn}` : '' }} className={`nav-link py-1 ps-3 text-${props.color}`}>
                                                        <span className='flex items-center'>
                                                            {subItem.title}
                                                        </span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
}

export default Offcanvase;
