import { HOME_FORM, CONTACTUS_FORM, PARTNER_FORM, QUOTE_FORM, CAREER_FORM, SUBSCRIBE_FORM, STAYTURN_FORM } from "../actions/Enquiry.action";

const initialState = {
    HomeForm: "",
    ContactUsForm: "",
    PartnerForm: "",
    QuoteForm: "",
    CareerForm: "",
    SubscribeForm: "",
    StayTurnForm: ""
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case HOME_FORM:
            return { ...state, HomeForm: action.payload };
        case CONTACTUS_FORM:
            return { ...state, ContactUsForm: action.payload };
        case PARTNER_FORM:
            return { ...state, PartnerForm: action.payload };
        case QUOTE_FORM:
            return { ...state, QuoteForm: action.payload };
        case CAREER_FORM:
            return { ...state, CareerForm: action.payload };
        case SUBSCRIBE_FORM:
            return { ...state, SubscribeForm: action.payload };
        case STAYTURN_FORM:
            return { ...state, StayTurnForm: action.payload };
        default:
            return state;
    }
}

export default adminReducer;